/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import DashIcon from '../icons/Dash';

const WhatsOnList = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulWhatsOnPage {
        name
        whatsOnList {
          featuredName
          slug
          featuredDescription {
            featuredDescription
          }
          heroImage {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const { contentfulWhatsOnPage } = data || {};
  const videoPosts = contentfulWhatsOnPage.whatsOnList.reverse();

  return (
    <section>
      <div className="md:px-[1.5rem] lg:px-[1.125rem] px-[1.5rem]">
        <div className="lg:py-[40px] py-[20px]">
          <div className="bg-background shadow-lg md:px-[1.5rem] pb-[60px]">
            <div className="mb-[20px] block">
              <h1 className="text-heading font-normal relative md:text-title1 mb-[2px]">
                {data.contentfulWhatsOnPage.name}
              </h1>
              <DashIcon />
            </div>
            <div className="flex flex-wrap">
              {videoPosts.map(({
                featuredName, slug, featuredDescription, heroImage,
              }, index) => (
                <Link key={index} className="mb-8 w-full md:w-1/2 lg:w-1/3 pr-[0.5rem] group duration-200 delay-75" to={`/whatson/${slug}`}>
                  <div className="relative">
                    <div className="h-[200px] transition transform duration-150 ease-in after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                      {slug === 'cyber-security'
                        ? <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt={featuredName} className="h-full shadow-md w-full z-0 group-hover:opacity-50 group-hover:shadow-lg" />
                        : <GatsbyImage image={heroImage.gatsbyImageData} alt={featuredName} className="h-full shadow-md w-full z-0 group-hover:opacity-50 group-hover:shadow-lg" />}
                    </div>
                  </div>
                  <h3 className="text-white group-hover:text-primary text-h3 mt-[8px] pb-[0.1rem]">{featuredName}</h3>
                  <p className="block text-text text-sm-1 font-light w-[80%]">
                    {`${featuredDescription.featuredDescription.substring(0, 50)}...`}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsOnList;
