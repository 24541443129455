/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import Seo from '../components/Seo';
import EpisodesLayout from '../components/EpisodesLayout';
import WhatsOnSection from '../components/WhatonSection';

const WhatsOn = () => (
  <div className="bg-background">
    <div className="mx-auto max-w-[85.375rem] bg-background shadow-xl shadow-[#000000ad]">
      <EpisodesLayout>
        <Seo title="Whats On" />
        <WhatsOnSection />
      </EpisodesLayout>
    </div>
  </div>
);

export default WhatsOn;
