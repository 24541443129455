/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CardList from './WhatsOnList';

const WhatsOnList = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulWhatsOnPage {
        pageBanner {
          buttonName
          buttonSlug
          title
          description {
            description
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const { contentfulWhatsOnPage } = data || {};
  const bannerPost = contentfulWhatsOnPage.pageBanner;

  return (
    <section>
      <div className="relative md:py-0 py-24 flex content-center items-center md:min-h-[30vh] min:h-[75vh] lg:min-h-[65vh] mb-[-5.4375] ">
        <div className="absolute h-full flex top-0 w-full bg-center bg-cover bg-gradient-to-br from-black via-gray-900 to-transparent">
          <GatsbyImage style={{ height: 'auto' }} image={bannerPost.image.gatsbyImageData} alt={bannerPost.title} className="w-full h-auto object-cover" />
        </div>
        <div className="relative md:px-[2rem] px-[1.5rem] lg:px-[2.5rem] w-full">
          <div className="flex flex-col justify-center items-center md:items-start space-y-5">
            <div className="w-full">
              <div className="md:pr-12 md:w-full lg:w-[65%] hidden lg:block">
                <h1 className="text-heading text-2xl md:text-[32px] text-center md:text-left font-semibold lg:text-6xl mb-[0.5]">
                  {bannerPost.title}
                </h1>
                <h2 className="lg:w-[60%] w-full mt-2 md:text-[22px] text-center md:text-left text-[18px] leading-6 md:leading-6 text-heading mb-4">
                  {bannerPost.description.description}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardList />
    </section>
  );
};

export default WhatsOnList;
